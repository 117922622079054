.settings {
    form {
        @media (max-width: map.get($grid-breakpoints, "sm")) {
            border: none !important;
            padding-left: 0;
        }

        input:invalid {
            border-left: 5px solid #a94442;
        }

        input:valid {
            border-left: 5px solid #42A948;
        }

        padding: 1rem;
        border: 1px solid #ddd;
    }

    .nav-link {
        color: #444;
    }

    .nav-link.active {
        text-decoration: none !important;
    }

    .nav-link:hover {
        text-decoration: underline;
    }

    .group-list {
        display: block;
        position: relative;
        z-index: 30;
        cursor: pointer;

        /* Sub Menus */
        .dropdown-menu {
            position: absolute;
            display: none;
            width: inherit;
            left: 0;
            top: 100%;
            padding: 0;
            background-color: $white;
            text-align: left;
            box-shadow: $material-shadow;

            a {
                display: block;
                padding: 5px 10px;
                cursor: pointer;
                white-space: nowrap;

                &:hover {
                    background-color: whitesmoke;
                }
            }
        }

        &.active {
            background-color: whitesmoke;

            .dropdown-menu {
                display: block;
            }
        }
    }
}

.user-list {
    padding: 2rem;
    background: #fff;
    border: 1px solid rgb(0 0 0 / 12.5%);

    h3::first-letter {
        text-transform: capitalize;
    }

    .user-card {
        display: inline-flex;
        align-items: center;
        padding: 2rem 2rem 0 0;
        flex-direction: column;

        .avatar {
            width: 6rem;
            height: 6rem;
            border-radius: 4px;

            &:hover {
                transform: scale(2);
            }
        }
    }
}
