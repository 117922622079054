@use 'sass:color';

.main-nav {
    background-color: $zusam-color;
    color: white;
    display: flex;
    flex-flow: row;
    height: $navbar-height;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 .125rem .25rem rgba(#000, .075);

    .nav-link:focus, .nav-link:hover {
      color: #fff;
    }

    .search-block {
        align-items: center;
        width: 100%;
        max-width: 30rem;
        padding: 0 5px;

        @media (width <= 400px) {
            &:focus-within {
                position: absolute;
                z-index: 2;
            }
        }
    }

    .menu {
        padding: 0 8px;
        position: relative;
        height: 100%;

        &.active {
            background-color: color.adjust($zusam-color, $hue: 5deg);
        }

        .avatar {
            height: 32px;
            width: 32px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            img {
                height: 100%;
            }
        }
    }

    .groups {
        cursor: pointer;
        position: relative;
        background-color: $zusam-color;
        height: 100%;
        line-height: 200%;
        flex-shrink: 0;

        .fa-icon {
            font-size: 0.7rem;
        }

        &.active {
            background-color: color.adjust($zusam-color, $hue: 5deg);
        }
    }

    .back {
        font-size: 1.2rem;
        padding: 0 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: color.adjust($zusam-color, $hue: 5deg);
        }
    }
}

.navbar-block {
    display: flex;
    height: 100%;
}
