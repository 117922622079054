.p-1 {
    padding: 1rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.font-size-80 {
    font-size: 80%;
}

.w-3 {
    width: 3rem !important;
}

.ml-1 {
    margin-left: 1rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.seamless-link {
    cursor: pointer;

    &, &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.z-index-100 {
    position: relative;
    z-index: 100;
}

.cursor-pointer {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

@mixin unselectable {
    user-select: none;
}

.unselectable {
    @include unselectable;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.d-none {
    display: none;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

@media (width >= 768px) {
    .d-md-block {
        display: block;
    }

    .d-md-flex {
        display: flex;
    }
}

.border-top {
    border-top: $border-width solid $border-color;
}

.border-bottom  {
    border-bottom: $border-width solid $border-color;
}

.rounded {
    border-radius: $border-radius;
}

.rounded-circle {
    border-radius: 50%;
}
