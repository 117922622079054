@use 'sass:color';

.login, .signup {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: radial-gradient($zusam-color, color.adjust($zusam-color, $hue: -5deg));
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: center;

    .login-form, .signup-form {
        width: calc(100% - 20px);
        max-width: 500px;
        display: flex;
        align-items: center;
        flex-flow: column;

        img {
            margin-bottom: 3rem;
        }

        form {
            width: 100%;
            max-width: 20rem;
            text-align: center;
        }
    }

    .welcome {
        color: white;
        text-align: center;
        margin-bottom: 3rem;
    }

    .forgot-password {
        display: block;
        margin-bottom: 1rem;
        text-align: right;
        color: white;
        font-size: 0.8rem;

        span:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .title {
        color: white;
        text-align: center;
        margin-bottom: 2rem;
        font-weight: bold;
    }
}
