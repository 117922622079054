.notifications-menu {
    left: -48px !important;
    width: 100vw;
}

.notification-header {
    font-size: 85%;
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    cursor: initial;

    .action {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notification {
    font-size: 90%;
    position: relative;
    padding-bottom: 7px !important;
    padding-left: 5px !important;

    &.unread {
      background-color: #f7a71b38;

      &:hover {
        background-color: #f7a71b6b !important;
      }
    }

    .miniature {
        display: inline-block;
        margin: 4px 10px 0 0;
        vertical-align: top;

        img {
            height: 60px;
            width: 60px;
            border-radius: 100%;
        }
    }

    .infos {
        display: inline-block;

        .description, .date {
            white-space: normal;
            padding-right: 5px;
        }

        .date {
            font-size: 90%;
        }
    }

    .options {
      bottom: 1px;
      position: absolute;
      right: 10px;
    }

    .dropdown .dropdown-menu.dropdown-options {
      bottom: 5px;
      top: initial;
      right: -2px;
      left: initial;
      position: absolute;
    }
}
